<template>
  <div class="col-10 offset-1 h-100">
    <div class="row no-gutters pt-2 pb-2">
      <div class="col-md-6">
        <p class="m-list-title">{{ this.payLoad.filePath }}</p>
      </div>
    </div>
    <b-table
      :items="getItems"
      :fields="fields"
      :current-page="currentPage"
      :per-page="perPage"
      stacked="md"
      show-empty
      small
      striped
      id="AppImportTable"
      thead-tr-class="m-table"
      :tbody-tr-class="rowClass"
    >
      <template #cell(data)="row">
        {{ chageFormatDate(row.item.eventDt) }}
      </template>

      <template #cell(nome)="row">
        {{ row.item.firstName }} {{ row.item.lastName }}
      </template>

      <template #cell(dataNascita)="row">
        {{ chageFormatDate(row.item.birthDt) }}
      </template>

      <template #cell(message)="row">
        
        <b-button size="sm" @click="row.toggleDetails" class="mr-2">
          Mostra
        </b-button>
      </template>
       <template #row-details="row">
        <b-card><div class="pl-1 pr-1" v-html="row.item.importMessage"></div> </b-card>
      </template>
      <!--<template #cell(actions)="row">
        <b-button
          size="sm"
          :to="{
            name: 'DettagliContatto',
            query: { id: row.item.id },
          }"
          class="mr-1"
        >
          Apri contatto
        </b-button>
      </template>-->
    </b-table>

    <b-pagination
      v-model="currentPage"
      :total-rows="totaItems"
      size="sm"
      :per-page="perPage"
      aria-controls="AppImportTable"
      align="center"
    ></b-pagination>
  </div>
</template>

<script>
import moment from "moment";

export default {
  props: ["getItems", "payLoad", "totaItems"],
  name: "AppImportContactTable",
  data() {
    return {
      fields: [
        {
          key: "data",
          label: "Data",
          sortable: true,
          thClass: "",
          tdClass: "d-flex justify-content-start ",
        },
        {
          key: "nome",
          label: "Nome Cognome",
          sortable: false,
        },
        {
          key: "dataNascita",
          label: "Data di Nascita",
          sortable: true,
        },

        {
          key: "email",
          label: "Email",
          sortable: true,
        },

        {
          key: "phoneNumber",
          label: "Telefono",
          sortable: true,
        },
        {
          key: "message",
          label: "Messaggio",
          sortable: false,
        },


        /* {
          key: "actions",
          label: "",
          sortable: false,
          thClass: "",
          tdClass: "d-flex justify-content-end ",
        }, */
      ],

      //Inizializazione tabella
      sortBy: "Ord",
      sortDesc: false,
      totalRows: 1,
      currentPage: 1,
      perPage: 15,
    };
  },

  methods: {
    rowClass(item, type) {
      if (!item || type !== "row") return;
      if (item.status !== "OK") return "table-danger";
    },

    chageFormatDate($date) {
      return moment.utc(new Date($date), "YYYY-MM-DD").format("DD/MM/YYYY");
    },

    async archiveRow($id) {
      try {
        await this.$store
          .dispatch("contact/archiveSingleContacts", {
            idContact: $id,
          })
          .then(() => {
            this.contact = this.$store.getters["contact/contact"];
            console.log("archiveRow");
            console.log(this.contact);
            return this.contact;
          });
      } catch (e) {
        console.log({ message: "Some error archiveSingleContacts" });
      }
    },
  },
};
</script>
